import * as React from 'react';
import {useEffect} from 'react';
import {
  PAYBACK_CODE_COOKIE,
  PAYBACK_STATE_COOKIE,
  ROUTE_PAYBACK_ACCOUNTBINDING,
} from '../../../common/constants';
import {navigate} from 'gatsby';
import * as Cookies from 'js-cookie';
import {
  B2CComponentsProvider,
  LoadingAnimationWrapper,
} from '@edekadigital/b2c-components';

const Accountbinding: React.FC = () => {
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const paybackCode = urlSearchParams.get('code') ?? undefined;
    const paybackState = urlSearchParams.get('state') ?? undefined;

    // Params must be set as cookie because the target page is reloaded by keycloak api and location params get lost
    Cookies.set(PAYBACK_CODE_COOKIE, paybackCode ?? '');
    Cookies.set(PAYBACK_STATE_COOKIE, paybackState ?? '');

    navigate!(ROUTE_PAYBACK_ACCOUNTBINDING);
    return;
  }, []);

  return (
    <B2CComponentsProvider>
      <LoadingAnimationWrapper isLoading={true} />
    </B2CComponentsProvider>
  );
};
export default Accountbinding;
